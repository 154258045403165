<template>
	<div>
		<small v-if="messageValidDob" class="text-danger">{{ messageValidDob }}</small>
		<v-select
			ref="selectSpecimens"
			v-model="selectedSpecimen"
			:clearable="false"
			label="description"
			:options="arraySpecimenToSelect"
			:reduce="(option) => option"
			placeholder="Ingresa la placa o alias de un ejemplar"
			@search="fetchOptionsSpecimens"
		>
			<template v-slot:option="option">
				{{ option.alias }} / ({{ option.plate }}) --
				{{ option.category_description }}
				<span class="font-weight-bolder">({{ option.status_description }})</span>
			</template>
		</v-select>
	</div>
</template>

<script>
import SpecimenService from "@/services/specimens.service"
import moment from "moment"

export default {
	name: "SelectSpecimens",
	props: {
		categories: {
			type: String,
			default: "1",
		},
		statusList: {
			type: [Array, String] || String,
			default: () => [],
		},
		selectedSpecimens: {
			type: Array,
			default: () => [],
		},
		cleanSelected: {
			type: Boolean,
			default: false,
		},
		skip: {
			type: Number,
		},
		flushSearched: [Boolean, Number],
		valid_dob: {
			type: Boolean,
			default: false,
		},
		specimen: {
			type: Object,
			default: () => {},
		},
		forceModel: Object,
	},
	data() {
		return {
			selectedSpecimen: null,
			arraySpecimenToSelect: [],
			messageValidDob: null,
		}
	},
	methods: {
		async fetchOptionsSpecimens(search, loading) {
			clearTimeout(this.debounce)
			this.debounce = setTimeout(async () => {
				loading(true)
				if (search.length > 1) {
					await this.getSpecimensToSelect(search)
				}
				loading(false)
			}, 300)
		},
		async getSpecimensToSelect(search) {
			const params = {
				search: search,
				categories: this.categories.length ? this.categories : null,
				statusList: this.statusList ? this.statusList : null,
			}
			let response = await SpecimenService.getSpecimensToSelect(params)
			//filter with selected specimens
			if (this.selectedSpecimens.length) {
				response = response.filter((specimen) => {
					return !this.selectedSpecimens.find(
						(selectedSpecimen) => selectedSpecimen.specimen_id === specimen.specimen_id
					)
				})
			}
			this.arraySpecimenToSelect = response.map((item) => {
				return {
					...item,
					description: `${item.plate} ${item.alias ? item.alias : ""}`,
					value: item.id,
				}
			})
		},
	},
	watch: {
		selectedSpecimen(newVal) {
			if (newVal) {
				this.$emit("selected", newVal)
				if (this.cleanSelected) {
					this.selectedSpecimen = null
					this.arraySpecimenToSelect = []
					this.$nextTick(() => {
						const input = this.$refs.selectSpecimens.$el.querySelector("input")
						input.focus()
					})
				}
				if (this.valid_dob) {
					const sp =
						this.arraySpecimenToSelect[
							this.arraySpecimenToSelect.findIndex((spe) => spe.specimen_id == newVal.specimen_id)
						]
					// console.log('specimen', newVal, );
					//1232
					const validDob = moment(this.specimen.dob) < moment(sp.dob)
					// console.log("DOB", validDob, this.specimen.dob, sp.dob);
					if (validDob || !sp.dob || !this.specimen.dob) {
						this.messageValidDob = "La fecha de nacimiento es invalida."
						this.selectedSpecimen = null
						this.$emit("selected", null)
						this.arraySpecimenToSelect = []
					} else {
						this.messageValidDob = null
					}
				}
			}
		},
		flushSearched() {
			this.selectedSpecimen = null
			this.arraySpecimenToSelect = []
			this.$nextTick(() => {
				const input = this.$refs.selectSpecimens.$el.querySelector("input")
				input.focus()
			})
		},
		forceModel(val, old) {
			if (!val) return
			this.getSpecimensToSelect(val.plate).then(() => {
				this.selectedSpecimen = this.arraySpecimenToSelect.find((opt) => opt.specimen_id == val.id)
				this.$emit("selected", this.selectedSpecimen)
			})
		},
	},
}
</script>

<style></style>
